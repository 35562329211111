var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq-item"
  }, [_c('button', {
    staticClass: "faq-item__head",
    attrs: {
      "aria-expanded": _vm.isOpen,
      "aria-controls": "collapse".concat(_vm._uid)
    },
    on: {
      "click": function click($event) {
        return _vm.toggleAccordion();
      }
    }
  }, [_vm._t("title"), _c('div', {
    class: [_vm.isOpen ? 'faq-item__arrow open' : 'faq-item__arrow']
  }, [_c('svg', {
    attrs: {
      "width": "12",
      "height": "7",
      "viewBox": "0 0 12 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.8582 0.820513C11.862 0.66466 11.8118 0.511612 11.7145 0.382646C11.6172 0.253679 11.4776 0.155203 11.3152 0.100908C11.1529 0.0466135 10.9758 0.0391982 10.8085 0.079693C10.6412 0.120188 10.4922 0.20658 10.382 0.32686L6.01353 4.91621L1.64658 0.32686C1.57864 0.243656 1.49237 0.173975 1.39312 0.122181C1.29386 0.0703862 1.18376 0.0375938 1.06978 0.0258568C0.955787 0.0141199 0.840358 0.0236915 0.73067 0.0539721C0.620982 0.0842527 0.51939 0.13459 0.432319 0.201832C0.345247 0.269073 0.274617 0.351771 0.224703 0.444746C0.174787 0.537721 0.146752 0.63897 0.142329 0.742152C0.137906 0.845334 0.157137 0.948226 0.198943 1.04439C0.240749 1.14055 0.304165 1.2279 0.38522 1.30098L5.3804 6.55537C5.45864 6.63789 5.55575 6.70415 5.66519 6.74958C5.77464 6.79502 5.89377 6.81854 6.0143 6.81854C6.13482 6.81854 6.25395 6.79502 6.3634 6.74958C6.47285 6.70415 6.57005 6.63789 6.64829 6.55537L11.6483 1.30098C11.7793 1.16825 11.8537 0.997998 11.8584 0.820513L11.8582 0.820513Z",
      "fill": "black"
    }
  })])])], 2), _c('div', {
    class: [_vm.isOpen ? 'faq-item__body open' : 'faq-item__body'],
    attrs: {
      "id": "collapse".concat(_vm._uid)
    }
  }, [_vm._t("content")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }