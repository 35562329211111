<template>
  <div class="preloader-header">
    <vue-skeleton-loader
      type="rect"
      :width="102"
      :height="41"
      :rounded="true"
      class="mt-6"
      :radius="5"
      animation="wave"
    />
    <div class="preloader-header-right">
      <vue-skeleton-loader
        type="rect"
        :width="41"
        :height="41"
        :rounded="true"
        class="mr-30 header-min"
        :radius="5"
        animation="wave"
      />
      <vue-skeleton-loader
        type="rect"
        :width="130"
        :height="41"
        :rounded="true"
        class="mr-30 header-min"
        :radius="5"
        animation="wave"
      />
      <vue-skeleton-loader
        type="rect"
        :width="41"
        :height="41"
        :rounded="true"
        :radius="5"
        animation="wave"
      />
    </div>
  </div>
</template>

<script>
  import VueSkeletonLoader from 'skeleton-loader-vue';
  export default {
    name: "HeaderLoading",
    components: { VueSkeletonLoader},
  }
</script>

<style scoped lang="scss">
  .preloader-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 18% 13px;
    background: #e8e8e8;

    @media (max-width: 768px) {
      padding: 12px 20px 13px;
    }

    &-right {
      display: flex;
    }
  }
</style>
