<template>
  <div class="faq">
    <div class="wrapper">
      <div class="faq-head faq-text-animation">
        <h3 class="faq-head__title">FAQ</h3>
        <div>
          <router-link :to="{ name: 'Faq' }" class="faq-head__all">View All</router-link>
        </div>
      </div>
      <div class="faq-body faq-text-animation">
        <app-accordion>
          <template v-slot:title>
            <span class="faq-body__name">What makes this auction different from others?</span>
          </template>
          <template v-slot:content>
            <p>
                We have launched the longest running auction in history. So, over time, the value of each
                NFT image increases. This is because the value of an NFT is not only in the image itself,
                but in the process of acquiring it.
            </p>
          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:title>
            <span class="faq-body__name">How do I bid in an auction?</span>
          </template>
          <template v-slot:content>
            <p>
                To make a bid in our auction, connect your Tezos Wallet, go to the page of the selected auction, and click "make a new bid".
                <br>
                Our platform will suggest you the bid amount that will be appropriate for you to take a place among the participants.
            </p>
          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:title>
            <span class="faq-body__name">Is it safe to connect my wallet to this auction?</span>
          </template>
          <template v-slot:content>
            <p>
                Yes, connecting your wallet to this auction is completely safe. We use API integration with the most popular Tezos exchanges. This gives you maximum protection against hacking.
            </p>
          </template>
        </app-accordion>
        <app-accordion>
          <template v-slot:title>
            <span class="faq-body__name">What is the minimum bid step in the auction?</span>
          </template>
          <template v-slot:content>
            <p>The minimum bid increment in our auction is 5 xtz.
            </p>
          </template>
        </app-accordion>

          <app-accordion>
              <template v-slot:title>
                  <span class="faq-body__name">What happens if I increase my bid at a different time of year from the season of my first bid?</span>
              </template>
              <template v-slot:content>
                  <p>
                      When you place your first bet, you are reserving an NFT tree image of a particular season. The time of year you increase your bid has no effect on the reservation.
                      <br>
                      If you want to compete for a tree of a different season, wait until that time of year and place a new bid.
                  </p>
              </template>
          </app-accordion>
      </div>
    </div>
    <div class="auctions-title-animation-start"></div>
  </div>
</template>
<script>
import AppAccordion from './AppAccordion.vue'
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(CSSPlugin);
export default {
  name: 'FaqBlock',
  components: {
    AppAccordion
  },
  mounted() {
    this.scrollTrigger()
  },
  methods: {
    scrollTrigger () {
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.defaults({
        start: '20px 80%',
        end: 'bottom 100px',
        scrub: true,
        // markers: true,
      })
      const texts = document.querySelectorAll('.faq-text-animation')
      Array.from(texts).map((item) => {
        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
          },
          duration: 1.5,
          opacity: 1,
        });
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .faq-text-animation {
    opacity: 0;
  }
  .faq {
    padding: 100px 0;
    background: #E4EEF4;
    @media (max-width: 768px) {
      padding: 60px 0;
    }
    .wrapper {
      flex-direction: column;
    }
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;
      &__title {
        font-size: 40px;
        line-height: 50px;
        font-weight: bold;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 30px;
          line-height: 36px
        }
      }
      &__all {
        font-size: 22px;
        line-height: 30px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
    &-body {
      width: 100%;
      &__name {
        font-size: 22px;
        line-height: 30px;
        color: #000000;
        font-weight: bold;
        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    &-item {
      &__body {
        p {
          margin: 0;
          padding: 0 30px 20px;
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          @media (max-width: 768px) {
            padding: 0 10px 20px;
          }
        }
      }
    }
  }
</style>
