var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader-header"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mt-6",
    attrs: {
      "type": "rect",
      "width": 102,
      "height": 41,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('div', {
    staticClass: "preloader-header-right"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mr-30 header-min",
    attrs: {
      "type": "rect",
      "width": 41,
      "height": 41,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "mr-30 header-min",
    attrs: {
      "type": "rect",
      "width": 130,
      "height": 41,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": 41,
      "height": 41,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }