<template>
  <div class="faq-item">
    <button
        @click="toggleAccordion()"
        class="faq-item__head"
        :aria-expanded="isOpen"
        :aria-controls="`collapse${_uid}`"
    >
      <slot name="title" />
      <div v-bind:class="[isOpen ? 'faq-item__arrow open'  : 'faq-item__arrow']">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.8582 0.820513C11.862 0.66466 11.8118 0.511612 11.7145 0.382646C11.6172 0.253679 11.4776 0.155203 11.3152 0.100908C11.1529 0.0466135 10.9758 0.0391982 10.8085 0.079693C10.6412 0.120188 10.4922 0.20658 10.382 0.32686L6.01353 4.91621L1.64658 0.32686C1.57864 0.243656 1.49237 0.173975 1.39312 0.122181C1.29386 0.0703862 1.18376 0.0375938 1.06978 0.0258568C0.955787 0.0141199 0.840358 0.0236915 0.73067 0.0539721C0.620982 0.0842527 0.51939 0.13459 0.432319 0.201832C0.345247 0.269073 0.274617 0.351771 0.224703 0.444746C0.174787 0.537721 0.146752 0.63897 0.142329 0.742152C0.137906 0.845334 0.157137 0.948226 0.198943 1.04439C0.240749 1.14055 0.304165 1.2279 0.38522 1.30098L5.3804 6.55537C5.45864 6.63789 5.55575 6.70415 5.66519 6.74958C5.77464 6.79502 5.89377 6.81854 6.0143 6.81854C6.13482 6.81854 6.25395 6.79502 6.3634 6.74958C6.47285 6.70415 6.57005 6.63789 6.64829 6.55537L11.6483 1.30098C11.7793 1.16825 11.8537 0.997998 11.8584 0.820513L11.8582 0.820513Z" fill="black"/>
        </svg>
      </div>
    </button>

    <div v-bind:class="[isOpen ? 'faq-item__body open'  : 'faq-item__body']" :id="`collapse${_uid}`">
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style scoped lang="scss">
.faq {
  &-item {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C2C2C2;
    border-radius: 20px;
    transition: .35s;
    @media (max-width: 768px) {
      border-radius: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &:hover {
      border: 1px solid #ED7D37;
      .faq-item__arrow {
        border: 1px solid #ED7D37;
        svg path {
          fill: #ED7D37;
        }
      }
    }
    &__head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 30px;
      @media (max-width: 768px) {
        padding: 15px 10px  ;
      }
    }
    &__arrow {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #C2C2C2;
      border-radius: 4px;
      transition: .35s;
      &.open {
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        transition: .35s;
        path {
          transition: .35s;
        }
      }
    }
    &__body {
      max-height: 0;
      height: auto;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
      @media (max-width: 768px) {
        margin-top: 2px;
      }
      &.open {
        max-height: 500px;
        transition: max-height 0.35s ease-in-out;
      }
    }
  }
}
</style>