var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faq"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "faq-head faq-text-animation"
  }, [_c('h3', {
    staticClass: "faq-head__title"
  }, [_vm._v("FAQ")]), _c('div', [_c('router-link', {
    staticClass: "faq-head__all",
    attrs: {
      "to": {
        name: 'Faq'
      }
    }
  }, [_vm._v("View All")])], 1)]), _c('div', {
    staticClass: "faq-body faq-text-animation"
  }, [_c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What makes this auction different from others?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" We have launched the longest running auction in history. So, over time, the value of each NFT image increases. This is because the value of an NFT is not only in the image itself, but in the process of acquiring it. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("How do I bid in an auction?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" To make a bid in our auction, connect your Tezos Wallet, go to the page of the selected auction, and click \"make a new bid\". "), _c('br'), _vm._v(" Our platform will suggest you the bid amount that will be appropriate for you to take a place among the participants. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("Is it safe to connect my wallet to this auction?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" Yes, connecting your wallet to this auction is completely safe. We use API integration with the most popular Tezos exchanges. This gives you maximum protection against hacking. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What is the minimum bid step in the auction?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v("The minimum bid increment in our auction is 5 xtz. ")])];
      },
      proxy: true
    }])
  }), _c('app-accordion', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "faq-body__name"
        }, [_vm._v("What happens if I increase my bid at a different time of year from the season of my first bid?")])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('p', [_vm._v(" When you place your first bet, you are reserving an NFT tree image of a particular season. The time of year you increase your bid has no effect on the reservation. "), _c('br'), _vm._v(" If you want to compete for a tree of a different season, wait until that time of year and place a new bid. ")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "auctions-title-animation-start"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }